/** @format */
import { ArrowDropDown, ArrowRight, ControlPoint } from "@mui/icons-material";
import {
  IResourceComponentsProps,
  useShow,
  useOne,
  useGetIdentity,
  useInvalidate,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@pankod/refine-core";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  DataGrid,
  Dialog,
  DialogActions,
  DialogTitle,
  GridColumns,
  Input,
  List,

  Typography,
  useDataGrid,
  ListItemText,

} from "@pankod/refine-mui";


import React, { useEffect, useState } from "react";

import { IGroup, IStudent, ISubGroup, IUser } from "interfaces";
import moment from "moment";
import UserAvatar from "../../components/custom";
import { StudentType } from "utils/type";

import { $fetch } from "utils/fetch";

export const GroupShow: React.FC<IResourceComponentsProps> = () => {
  const { show, create } = useNavigation();
  const { data: user } = useGetIdentity();
  const notification = useNotification();
  const invalidate = useInvalidate();
 
  const routerContext = useRouterContext();
  

  const location = routerContext.useLocation();
  const navigate = useNavigation();
  const { showId } = useShow();


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const [menuItems, setMenuItems] = useState<IGroup[]>([]); // State for fetched items
  const [loading, setLoading] = useState(true);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenuAnchorE2, setSubMenuAnchorE2] = useState<null | HTMLElement>(null);
  const [activeSubMenuItems, setActiveSubMenuItems] = useState<ISubGroup[]>([]);

// Handle the open/close state of the dropdown menu
const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
   
  setAnchorEl(event.currentTarget);
};
const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {

  
  setAnchorE2(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
  setAnchorE2(null);
  setSubMenuAnchorEl(null);
  setSubMenuAnchorE2(null);
};

useEffect(() => {
const fetchMenuItems = async () => {
    try {
        setLoading(true);
       
        const res = await $fetch<IGroup[]>(`admin/teacherGroup`)
       if(res.data){

         setMenuItems(res.data); 
       }
    } catch (error) {
        console.error("Error fetching menu items:", error);
    } finally {
        setLoading(false);
    }
};

fetchMenuItems();
}, []);

// Handle sub-menu open
const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>, subOptions: ISubGroup[]) => {
setSubMenuAnchorEl(event.currentTarget);
setSubMenuAnchorE2(event.currentTarget);
setActiveSubMenuItems(subOptions); // Set active sub-options for this sub-menu
};

// Handle sub-menu close
const handleSubMenuClose = () => {
setSubMenuAnchorEl(null);
setSubMenuAnchorE2(null);
};

// Handle actions for each menu item
const handleMenuItemClick = async (option: string) => {
await $fetch("admin/teacherGroup/assign", {
  body: {
    groupId: option,
    studentIds: selected,
     
  },
  method: "POST"
})
  handleClose();
};
const handleSubMenuItemClick = async (option: string) => {
await $fetch("admin/teacherGroup/subgroups/assign", {
  body: {
    subGroupId: option,
    studentIds: selected,
     
  },
  method: "POST"
})
  handleClose();
};


  const getSearchParams = () => new URLSearchParams(location.search);
  const updateSearchParams = (params: Record<string, string>) => {
    const searchParams = getSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    navigate.push(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };
const id = String(showId);
  const {  data, isLoading, isError } = useOne<ISubGroup>({
 
    resource: `teacherGroup`, 
    id,
                       
});

  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);
  // @ts-ignore
  // @ts-ignore
  const { dataGridProps, setFilters, filters, setCurrent } = useDataGrid<IStudent>({
    resource: "student",
    initialPageSize: parseInt(getSearchParams().get('pageSize') || '10'),
    initialCurrent: parseInt(getSearchParams().get('page') || '1'),
    permanentFilter: (data?.data.id)? [
      {
        field: "groupId",
        operator: "eq",
        value: data?.data.id, 
      }
    ]:[],
    initialSorter: [
      {
        field: "last_active",
        order: "desc",
      },
    ],

  });


  const defaultColumns = React.useMemo<GridColumns<StudentType>>(
    () => [
      {
        field: "firstName",
        headerName: "Full Name",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const row = params.row;
          return (
            <UserAvatar
              user={
                {
                  firstName: row.firstName,
                  lastName: row.lastName,
                  user_status: row.user_status,
                  last_active: row.last_active,
                } as unknown as IUser
              }
            />
          );
        },
      },
      ...(user?.userType !== "parent" ? [{
        field: "stateCode",
        headerName: "State",
        minWidth: 120,
        flex: 1,
        valueGetter(v) {
          return v.row.stateCode;
        },
      }] : []),
      ...(user?.userType !== "parent" ? [{
        field: "name",
        headerName: "School Name",
        flex: 1,
        minWidth: 150,
        valueGetter(v) {
          return v.row.name;
        },
      },] : []),
      {
        field: "grade",
        headerName: "Grade",
        flex: 1,
        minWidth: 100,
        valueGetter(v) {
          return v.row.grade;
        },
      },
      ...(user?.userType !== "parent" ? [{
        field: "teacherFirstName",
        headerName: "Teacher",
        minWidth: 160,
        flex: 1,
        renderCell(params) {
          const teacher = params.row.teacherFirstName;
          return (
            <>
              <Avatar
                sx={{ width: 24, height: 24 }}
                src={params.row.photoURL}
                alt={teacher}
              />
              <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
                {teacher}
              </Typography>
            </>
          );
        },
      }] : []),
      {
        field: "progress_lessonId",
        headerName: "Fluency Progress",
        minWidth: 120,
        flex: 1,
        valueGetter(params) {
          const progresses = (params.row as any).progress_lessonId;
          return progresses;
        },
        filterable: true,
        renderCell(params) {
          console.log(params.row)
          const parsed = parseInt(params.value);
          const progress = isNaN(parsed) ? 0 : parsed;
          const pretest = params.row.introductionPresented === 0 ? false : true;
          const isComplete = params.row.isComplete === 1 ? true : false;
          return (
            <Typography>
              <b>
                {progress <= 0
                  ? pretest
                    ? "Pre Test"
                    : "Not Started"
                  : isComplete
                    ? "Completed"
                    : `Lesson ${progress}`}
              </b>
            </Typography>
          );
        },
      },
      {
        field: "ctProgressItemNumber",
        headerName: "CT Progress",
        minWidth: 120,
        flex: 1,
        renderCell(params) {
          // const license = params.row.license;
          // const expiresOn = moment(license.expiresOn);
          // const createdOn = moment(license.created_at);
          const progress = params.row.ctProgressItemNumber ?? 0;
          return progress >= 160 && params.row.isComplete ? (
            <b>Complete</b>
          ) : progress ? (
            <span>
              Lesson <b>{Math.ceil((progress - 4) / 4) + 1}</b>
              <br />
              Activity <b>{((progress - 1) % 4) + 1}</b>
            </span>
          ) : (
            <b>Not Started</b>
          );
        },
      },
      {
        field: "last_active",
        headerName: "Last Active",
        flex: 1,
        minWidth: 150,
        renderCell(params) {
          var diffTime = moment(params.row.last_active).fromNow();

          // var lastLesson =
          //   progresses
          //     .sort((a, b) => b.last_active - a.last_active)
          //     .find(
          //       (a) => moment(new Date()).diff(a.last_active, "minute") < 1
          //     ) || null;

          return (
            <Typography variant="body1">
              {diffTime}
              {/* {lastLesson && (
                <>
                  <br />
                  on{" "}
                  <b>
                    {lastLesson.lessonId > 0 ? (
                      <>
                        Lesson <b>{lastLesson.lessonId}</b>
                      </>
                    ) : (
                      <b>Pre-Test</b>
                    )}
                  </b>
                </>
              )} */}
            </Typography>
          );
        },
      },
      {
        field: "expiresOn",
        headerName: "License Expiry",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const license = params.row;
          const expiresOn = moment(license.expiresOn);
          const createdOn = moment(license.created_at);
          return (
            <Typography variant="body1">
              {expiresOn.format("MM/DD/YYYY")}
              <br />
              <b>({expiresOn.fromNow()})</b>
            </Typography>
          );
        },
      },
      {
        field: "Delete",
        headerName: "Actions",
      },
    ].filter((col) => {
      if (user?.userType === "parent") {
        return col.field !== "teacherFirstName";
      }
      return true;
    }),
    [],
  );

  const [hideUnselected, setHideUnselected] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <List
      cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}
      title = {
        data?.data.name&&
        <Typography variant="h4" sx={{fontWeight: 'bold'}}>{data?.data.name}</Typography>}
       
      headerButtons={(context) => {
        return (
          <>
            
            {(user?.userType === "teacher") && (
              <div className="flex gap-10">

<div className="flex gap-2 text-white transition-all">
            <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                endIcon={<ArrowDropDown />}
            >
                Class
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
              <MenuItem  onClick={() => create('teachergroup')}><ControlPoint />  Create</MenuItem>

{loading ? (
                    <MenuItem disabled>Loading...</MenuItem>
                ) : (
                    menuItems.map((item, index) => (
                        <div key={index}>
                            <MenuItem
                            
                                onClick={(event) => { 
                                  event.stopPropagation()
                                  show("teachergroup", item?.id || "me")}}
                                // onMouseEnter={(event) =>{
                                //   event.stopPropagation()
                                //     // item.subGroups && item.subGroups.length > 0?
                                //          handleSubMenuOpen(event, item.subGroups)}
                                //         // : undefined
                                // }
                                aria-haspopup={!!item.subGroups?.length}
                            >
                                <ListItemText primary={item.name} />
                                {/* item.subGroups && item.subGroups.length > 0 &&  */}
                                <div onMouseEnter={(event)=> handleSubMenuOpen(event, item.subGroups)}>

                                <ArrowRight/>
                                </div>
                            </MenuItem>
                            {/* Sub-menu */}
                            {/* {item.subGroups && item.subGroups.length > 0 && ( */}
                                <Menu
                                    anchorEl={subMenuAnchorEl}
                                    open={Boolean(subMenuAnchorEl)}
                                    // open={Boolean(subMenuAnchorEl) && activeSubMenuItems === item.subGroups}
                                    onClose={handleSubMenuClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                >
                                  <MenuItem  onClick={() => create('teacherGroup/subgroups')}><ControlPoint />  Create</MenuItem>
                                    {activeSubMenuItems.map((subOption, subIndex) => (
                                        <MenuItem key={subIndex} onClick={() => show("group", subOption?.id || "me")}>
                                            {subOption.name}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            {/* )} */}
                        </div>
                    ))
                )}


              
            </Menu>
        </div>

        {/* {context.defaultButtons} */}
             
              </div>
            )}
            
           
          </>
        );
      }}
    >
     
      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {
            (!!selected.length || hideUnselected) && (
              <div className="flex items-center gap-2">
               {(user?.userType === "teacher") && (
                <><Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClick2}
                    endIcon={<ArrowDropDown />}
                  >
                    Add to ...
                  </Button>
                  <Menu
                    anchorEl={anchorE2}
                    open={Boolean(anchorE2)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >

                      {loading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                      ) : (
                        menuItems.map((item, index) => (
                          <div key={`sub${index}`}>
                            <MenuItem
                              onClick={() => handleMenuItemClick(item.id)}
                             
                              aria-haspopup={!!item.subGroups?.length}
                            >
                              <ListItemText primary={item.name} />
                              {item.subGroups && item.subGroups.length > 0 && <div onMouseEnter={(event)=>item.subGroups && item.subGroups.length > 0
                                ? handleSubMenuOpen(event, item.subGroups)
                                : undefined}> <ArrowRight /></div>}
                            </MenuItem>
                            {/* Sub-menu */}
                            {item.subGroups && item.subGroups.length > 0 && (
                              <Menu
                                anchorEl={subMenuAnchorE2}
                                open={Boolean(subMenuAnchorE2) && activeSubMenuItems === item.subGroups}
                                onClose={handleSubMenuClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                {activeSubMenuItems.map((subOption, subIndex) => (
                                  <MenuItem key={subIndex} onClick={() => handleSubMenuItemClick(subOption.id)}>
                                    {subOption.name}
                                  </MenuItem>
                                ))}
                              </Menu>
                            )}
                          </div>
                        ))
                      )}

                    </Menu></>)}


                <Button color="error" variant="outlined" onClick={() => {
                  setShowDeleteDialog(true)
                }}>
                  Remove Selected
                </Button>
              </div>
            )
          }

        </div>
      </div>

      <DataGrid
        {...dataGridProps}
        onPageChange={(page, details) => {
          updateSearchParams({ page: page.toString() });
          dataGridProps.onPageChange?.(page, details);
        }}
        onPageSizeChange={(pageSize, details) => {
          updateSearchParams({ pageSize: pageSize.toString(), page: '1' });
          dataGridProps.onPageSizeChange?.(pageSize, details);
        }}
        columns={defaultColumns}
        checkboxSelection
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}

        disableSelectionOnClick
        sortingOrder={["asc", "desc"]}
        onColumnHeaderClick={(col) => {
          if (col.field === "__check__") {
            setSelected([])
          }

        }}
        onCellClick={(cell) => {
          if (cell.colDef.field === "school") {
            show("school", cell.row.school.id);
          } else if (cell.colDef.field === "teacher") {
            show("teacher", cell.value);
          } else if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected(prev => prev.filter(id => id !== cell.row.id))
            } else {
              setSelected(prev => [...prev, cell.row.id])
            }
          } else {
            show("student", cell.row.id);
          }
        }}


      />
   
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>
          Are you sure you want to remove the selected students?
        </DialogTitle>
        <DialogActions className="px-4 py-2">
          <Button
            onClick={async () => {
             
              const { error } = await $fetch("admin/teachergroup/remove", {
                body: {
                    groupId: id,
                    studentIds: selected
                 
                },
                method: "POST",
                
            })

            if (error) {
                notification.open?.({
                    message: "Error",
                    description: error.statusText,
                    type: "error"
                })
            } else {
                notification.open?.({
                    message: "Success",
                    description: "Lesson has been reset",
                    type: "success"
                })
                invalidate({
                    resource: "student",
                    invalidates: ["all"],
                });
            }
              
             
              setShowDeleteDialog(false)
            }}
            color="error"
          >
            Remove
          </Button>
          <Button
            onClick={() => {
              setShowDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </List>
  );
};


