import React, { useState } from "react";
import {
    IResourceComponentsProps,
    HttpError,
    useNavigation,
    useRouterContext,
    useDataProvider
} from "@pankod/refine-core";
import {
    Controller,
    useForm
} from "@pankod/refine-react-hook-form";
import {
    FormLabel,
    Stack,
    Box,
    FormControl,
    Autocomplete,
    OutlinedInput,
    FormHelperText,
    Create,
    useAutocomplete,
    TextField,
} from "@pankod/refine-mui";

import { IGroup, ISubGroup } from "interfaces";


export const SubGroupCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = process.env.REACT_APP_BACKEND;
    const {goBack} = useNavigation();
    const { useLocation } = useRouterContext();
    const [group, setGroup] = useState<IGroup | null>(null);
    const dataProvider = useDataProvider();
    const defaultDataProvider = dataProvider();
    
    const { autocompleteProps } = useAutocomplete<IGroup>({
        resource: "teachergroup",
        errorNotification: false,
    });

    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        saveButtonProps
    } = useForm<ISubGroup, HttpError, ISubGroup>({
        refineCoreProps: {
            redirect: false,
            onMutationSuccess(data, variables, context) {
                goBack();
            },
        }
    });

   

    return (
        <Create
            saveButtonProps={saveButtonProps}
        >
            <Stack>
                <Box
                    paddingX="50px"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingX: {
                            xs: 1,
                            md: 6,
                        },
                    }}
                >
                    <form onSubmit={handleSubmit(onFinish)}>
                        <Stack gap="40px" direction={"row"}>
                            
                            <Stack flex={1} gap="10px" marginTop="10px">
                            <FormControl fullWidth>
                                    <FormLabel
                                        // required
                                        sx={{
                                            marginBottom: "8px",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            color: "text.primary",
                                        }}
                                    >
                                        Class Name
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="groupId"

                                        // rules={{
                                        //     required: "School required",
                                        // }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                size="small"
                                                {...autocompleteProps}
                                               
                                                // {...field}
                                                // value={field.value || null}
                                                value={group}
                                                onChange={(_, value) => {
                                                    setGroup(value);
                                                    field.onChange(
                                                        value?.id
                                                    );
                                                }}
                                                getOptionLabel={(item) => {
                                                    return item.name
                                                        ? item.name
                                                        : "";
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) =>
                                                    value === undefined ||
                                                    option.id === value.id
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        // error={
                                                        //     !!errors.groupId
                                                        //         ?.message
                                                        // }
                                                        // required
                                                    />
                                                )}
                                            />
                                         )}
                                    /> 
                                    {/* {errors.groupId && (
                                        <FormHelperText error>
                                            {errors.groupId.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>

                              
                                <FormControl>
                                    <FormLabel required>
                                        Group Name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="name"
                                        {...register("name", {
                                            required: "name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.name && (
                                        <FormHelperText error>
                                            {errors.name.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                       Group Description
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        {...register("description")}
                                        style={{ height: "40px" }}
                                    />
                                    {/* {errors.description && (
                                        <FormHelperText error>
                                            {errors.description.message}
                                        </FormHelperText>
                                    )} */}
                                </FormControl>

                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Stack>
        </Create>
    );
};