import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';

export function generateExcelFile(
  input: {
    data: (string | number)[][];
    column: string[];
    sectionHeader: string;
  }[],
  fileName?: string
) {
  const rows: any[] = [];
  input.forEach(({ data, column, sectionHeader }) => {
    const sectionRow = [sectionHeader];
    rows.push(sectionRow, column, data);
  });
  const worksheet = XLSX.utils.aoa_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  saveAs(blob, `${fileName} ${new Date().toDateString()}.xlsx`);
}

export function s2ab(s: string): ArrayBuffer {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export async function exportAsPdf(selector: string, name: string, multiplePages: boolean = false, pageCount?: number) {
  const pdf = new jsPDF("portrait", "pt", "a4", true);
  const element = document.querySelector(selector) as HTMLElement;
  const title = document.querySelector('#title') as HTMLElement;
if(title){
  title.style.display = "block"
  element.style.display = "block"
  element.prepend(title)
} else
{
  element.style.display = "block"
}
  const options = {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: document.documentElement.offsetWidth,
    windowHeight: element.scrollHeight,
  }
  const data = await html2canvas(element, options);
  if(title){title.style.display = 'none'}
  const img = data.toDataURL("image/jpeg", 0.5)
  const imgProperties = pdf.getImageProperties(img);
  const pdfWidth = pdf.internal.pageSize.getWidth()-38;
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  let heightLeft = imgProperties.height;
  let position = 0;
  const pageHeight = pdf.internal.pageSize.getHeight();

  if (multiplePages) {
    pageCount = pageCount || Math.ceil(heightLeft / pageHeight);
    while (pageCount--) {
      try {
        pdf.addImage(img, "JPEG", 19, position+19, pdfWidth, pdfHeight-50);
        heightLeft -= pageHeight;
        console.log(heightLeft, pageHeight);
        // Only add a new page if there's more than a page's height left
        if (heightLeft - pageHeight > 0) {
          pdf.addPage();
          position -= pageHeight;
        }
      } catch (e) {
        console.log(e);
      }
    }
  } else {
    pdf.addImage(img, "JPEG", 19, position+19, pdfWidth, pdfHeight);
  }
  pdf.save(`${name}.pdf`);
}
export async function exportAsPdfCropped(selector: string, name: string, multiplePages: boolean = false, pageCount?: number) {
  const pdf = new jsPDF("portrait", "pt", "a4", true);
  const element = document.querySelector(selector) as HTMLElement;
  const title = document.querySelector('#title') as HTMLElement;
if(title){
  title.style.display = "block"
  element.style.display = "block"
  element.prepend(title)
} else
{
  element.style.display = "block"
}
  const options = {
    useCORS: true, allowTaint: true, scrollY: 0,
    // scrollX: -window.scrollX,
    // scrollY: -window.scrollY,
    // windowWidth: document.documentElement.offsetWidth,
    // windowHeight: element.scrollHeight,
  }

  html2canvas(element,options).then((canvas) => {
    const image = { type: 'jpeg', quality: 0.98 };
    const margin = [0.5, 0.5];
    // const filename = 'myfile.pdf';

    var imgWidth = 8.5;
    var pageHeight = 11;

    var innerPageWidth = imgWidth - margin[0] * 2;
    var innerPageHeight = pageHeight - margin[1] * 2;

    // Calculate the number of pages.
    var pxFullHeight = canvas.height;
    var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
    var nPages = Math.ceil(pxFullHeight / pxPageHeight);

    // Define pageHeight separately so it can be trimmed on the final page.
    var pageHeight = innerPageHeight;

    // Create a one-page canvas to split up the full image.
    var pageCanvas = document.createElement('canvas');
    var pageCtx = pageCanvas.getContext('2d');
    pageCanvas.width = canvas.width;
    pageCanvas.height = pxPageHeight;

    // Initialize the PDF.
    var pdf = new jsPDF('p', 'in', [8.5, 11]);

    for (var page = 0; page < nPages; page++) {
      // Trim the final page to reduce file size.
      if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
        pageCanvas.height = pxFullHeight % pxPageHeight;
        pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
      }

      // Display the page.
      var w = pageCanvas.width;
      var h = pageCanvas.height;
      if(pageCtx!=null){

        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);
      }

      // Add the page to the PDF.
      if (page > 0) pdf.addPage();
      debugger;
      var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
      pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
    }

  if(title){title.style.display = 'none'}
  
  pdf.save(`${name}.pdf`);
})}


// export async function exportAsPdf(selector: string, name: string) {
//   const htmlRef = document.querySelector(selector) as HTMLElement;
//   html2canvas(htmlRef, {
//     scrollX: -window.scrollX,
//     scrollY: -window.scrollY,
//     windowWidth: document.documentElement.offsetWidth,
//     windowHeight: htmlRef.scrollHeight,
//   }).then((canvas) => {
//     const img = new Image();
//     const imgData = canvas
//       .toDataURL("image/png")
//       .replace("image/png", "image/octet-stream");

//     const pdf = new jsPDF("p", "mm", "a4");
//     const imgProps = pdf.getImageProperties(imgData);
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
//     pdf.save(`${name}.pdf`);
//   });
// }

export function tFormat(time: number) {
  return time < 60
    ? `${time} min`
    : `${Math.floor(time / 60)}hr ${time % 60}min`;
}

// First 16
// Second 59
// Third 79
// Fourth 95
// Fifth 109
// Sixth 116
// Seventh 117
// Eighth 123

export function getGradeLevelFromWcpm(wcpm: number) {
  return wcpm <= 16 ? "First" : wcpm <= 59 ? "Second" : wcpm <= 79 ? "Third" : wcpm <= 95 ? "Fourth" : wcpm <= 109 ? "Fifth" : wcpm <= 116 ? "Sixth" : wcpm <= 117 ? "Seventh" : "Eighth";
}

export const cn = (...args: any[]) => args.filter(Boolean).join(" ");