/** @format */

import React, { useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
} from "@pankod/refine-core";
import {
  DataGrid,
  useDataGrid,
  GridColumns,
  Input,
  List,
  Typography,
  Button,
} from "@pankod/refine-mui";

import { ISubGroup } from "interfaces";
import moment from "moment";

export const SubGroupList: React.FC<IResourceComponentsProps> = () => {
  const { show, create } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();

  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);

  const { dataGridProps, setFilters, filters, setCurrent } =
    useDataGrid<ISubGroup>({
      permanentFilter:
        search?.length > 0
          ? [
              {
                // @ts-ignore
                field: "title_like",
                operator: "eq",
                value: search,
              },
            ]
          : [],
      initialPageSize: 10,
      initialSorter: [
        {
          field: "id",
          order: "desc",
        },
      ],
    });

  const columns = React.useMemo<GridColumns<ISubGroup>>(
    () => [
      {
        field: "name",
        headerName: "Group Name",
        minWidth: 100,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
     
          return (
            <>
              {/* <Avatar src={principal?.photoURL} alt={principal?.firstName} /> */}
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.name}
              </Typography>
            </>
          );
        },
      },
      {
        field: "description",
        headerName: "description",
        minWidth: 200,
        flex: 1,
        renderCell(params) {
          const principal = params.row;
          return (
            <>
              {/* <Avatar src={principal?.photoURL} alt={principal?.firstName} /> */}
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                {principal?.description}
              </Typography>
            </>
          );
        },
      },

      {
        field: "students",
        headerName: "students",
        flex: 1,
        minWidth: 200,
        renderCell(params) {
          return (
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {params.row.students}
            </Typography>
          );
        },
      },
   
      {
        field: "created_at",
        headerName: "Created On",
        flex: 1,
        minWidth: 200,
        valueFormatter(v) {
          const dt = moment(v.value);
          return dt.format("L") + ` (${dt.fromNow()})`;
        },
      },
    //   {
    //     field: "actions",
    //     headerName: "Actions",
    //     width: 150,
    //     renderCell: (params) => (
    //         <Button
    //             variant="contained"
    //             color="primary"
    //             // onClick={() => navigate(`/students/${params.row.id}`)}
    //         >
    //             View Details
    //         </Button>
    //     ),
    // },
    ],
    []
  );


  const [hideUnselected, setHideUnselected] = useState(false);

  return (
    <List cardProps={{ sx: { paddingX: { xs: 2, md: 0 } } }}>
      <div className="flex items-center justify-between">
        <Input
          onChange={(e) => {
            setSearch(e.currentTarget.value!);
          }}
          placeholder={"Type here to search"}
          style={{ margin: 10 }}
        />
        <div>
          {(!!selected.length || hideUnselected) && (
            <div className="flex items-center gap-2">
              {/* <Button color="secondary" onClick={() => {
                  setReportCardModal(true)
                }}>
                  Generate Report Card
                </Button> */}
              <Button
                onClick={() => {
                  if (!hideUnselected) {
                    setHideUnselected(true);
                    setFilters([
                      ...filters,
                      {
                        field: "id",
                        operator: "in",
                        value: selected,
                      },
                    ]);
                    setCurrent(1);
                  } else {
                    setHideUnselected(false);
                    //@ts-expect-error
                    setFilters((prev) => prev.filter((f) => f.field !== "id"));
                  }
                }}
              >
                {hideUnselected ? "Show All" : "Hide Unselected"}
              </Button>
            </div>
          )}
        </div>
      </div>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        rowsPerPageOptions={[10, 20, 50, 100]}
        density="comfortable"
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
        checkboxSelection
        onCellClick={(cell) => {
          if (cell.colDef.field === "__check__") {
            if (selected.includes(cell.row.id)) {
              setSelected((prev) => prev.filter((id) => id !== cell.row.id));
            } else {
              setSelected((prev) => [...prev, cell.row.id]);
            }
            return;
          }
          if (cell.colDef.field == "school") {
            show("school", cell.row.school.id);
          } else {
            show("teacher", cell.row.id);
          }
        }}
      />
    </List>
  );
};
