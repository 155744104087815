"use client";

import { useState, useRef, useEffect } from "react";
import lessonData from "../../const/flData.json";
import style from "./resource.module.css";
import { useNavigation, useRouterContext } from "@pankod/refine-core";

const lesson6 = lessonData.lesson6.data;
const lesson8 = lessonData.lesson8.data;
const lesson10 = lessonData.lesson10.data;
const lesson13 = lessonData.lesson13.data;
const lesson15 = lessonData.lesson15.data;
const lesson18 = lessonData.lesson18.data;

const data = [
  {
    lessonId: 6,
    data: Object.keys(lesson6)
      .filter((k) => k.startsWith("T"))
      .map((k) => ({
        word: lesson6[k].text,
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/6/answers/${k.toLowerCase()}.mp3`,
      })),
  },
  {
    lessonId: 8,
    data: Object.keys(lesson8)
      .filter((k) => k.startsWith("W") && lesson8[k].text)
      .map((k) => ({
        word: lesson8[k].text,
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/8/answers/${k.toLowerCase()}.mp3`,
      })),
  },
  {
    lessonId: 10,
    data: Object.keys(lesson10)
      .filter((k) => k.startsWith("W") && lesson10[k].text)
      .map((k) => ({
        word: lesson10[k].text,
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/10/answers/${k.toLowerCase()}.mp3`,
      })),
  },
  {
    lessonId: 13,
    data: Object.keys(lesson13)
      .filter((k) => k.startsWith("W") && lesson13[k].text)
      .map((k) => ({
        word: lesson13[k].text,
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/13/answers/${k.toLowerCase()}.mp3`,
      })),
  },
  {
    lessonId: 15,
    data: Object.keys(lesson15)
      .filter((k) => k.startsWith("W") && lesson15[k].text)
      .map((k) => ({
        word: lesson15[k].text.replace("-", ""),
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/15/answers/${k.toLowerCase()}.mp3`,
      })),
  },
  {
    lessonId: 18,
    data: Object.keys(lesson18)
      .filter((k) => k.startsWith("W") && lesson18[k].text)
      .map((k) => ({
        word: lesson18[k].text.replace("<mark>", "").replace("</mark>", ""),
        audio: `https://d3vx0tne0vxeo1.cloudfront.net/audios/lesson/18/answers/${k.toLowerCase()}.mp3`,
      })),
  },
];

const colors = [
  "bg-[#0047AB] hover:bg-[#003C8F]", // Cobalt Blue
  "bg-[#4CAF50] hover:bg-[#45a049]", // Grass Green
  "bg-[#FFC300] hover:bg-[#E6B000]", // Warm Gold
];

export default function ResourcePage() {
  const [resourceId, setResourceId] = useState<number | null>(null);
  const [playingAudio, setPlayingAudio] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const handleLessonClick = (id: number) => {
    setResourceId(id);
    setPlayingAudio(null);
  };

  const playAudio = (audioUrl: string) => {
    if (audioRef.current) {
      if (playingAudio === audioUrl) {
        audioRef.current.pause();
        setPlayingAudio(null);
      } else {
        audioRef.current.src = audioUrl;
        audioRef.current.play();
        setPlayingAudio(audioUrl);
      }
    }
  };

  const navigate = useNavigation();
  const location = useRouterContext().useLocation();

  const updateSearchParams = (params: Record<string, string>) => {
    const searchParams = new URLSearchParams(location.search);
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    navigate.push(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const getSearchParams = () => new URLSearchParams(location.search);
    const param = getSearchParams().get("lessonId");
    if (param) {
      setResourceId(parseInt(param));
    }
  }, [location.search]);

  useEffect(() => {
    if (resourceId) {
      updateSearchParams({
        lessonId: resourceId.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId]);

  useEffect(() => {
    const handleAudioEnd = () => {
      setPlayingAudio(null);
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("ended", handleAudioEnd);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleAudioEnd);
      }
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col" style={style}>
      <div className="sticky top-0 bg-white z-20 shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                src="/images/read21.svg"
                alt="Read21 Logo"
                style={{ width: "100%", maxHeight: 70 }}
              />
              <h1 className="text-xl font-bold">Resource Page</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow container mx-auto px-4 py-8 flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-1/3 md:sticky md:top-24 md:self-start">
          <h2 className="text-xl font-semibold mb-4">Lessons</h2>
          <ul className="space-y-2 max-h-[calc(100vh-12rem)] overflow-y-auto">
            {data.map((lesson) => (
              <li key={lesson.lessonId}>
                <button
                  onClick={() => handleLessonClick(lesson.lessonId)}
                  className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                    resourceId === lesson.lessonId
                      ? "bg-blue-500 text-white"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                >
                  Lesson {lesson.lessonId}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-2/3">
          <h2 className="text-xl font-semibold mb-4">Resource Content</h2>
          <div className="bg-gray-100 rounded-lg p-6">
            {resourceId ? (
              <>
                <h3 className="text-lg font-medium mb-4">
                  Lesson {resourceId}
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                  {data
                    .find((l) => l.lessonId === resourceId)
                    ?.data.map((item, index) => (
                      <button
                        key={index}
                        onClick={() => playAudio(item.audio)}
                        className={`w-24 h-24 rounded-full ${
                          colors[index % colors.length]
                        } text-white flex flex-col items-center justify-center text-sm font-medium transition-colors relative`}
                      >
                        <span>{item.word}</span>
                        <div className="absolute bottom-2">
                          {playingAudio === item.audio ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="w-5 h-4"
                            >
                              <rect x="14" y="4" width="4" height="16" rx="1" />
                              <rect x="6" y="4" width="4" height="16" rx="1" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="w-4 h-4"
                            >
                              <polygon points="6 3 20 12 6 21 6 3" />
                            </svg>
                          )}
                        </div>
                      </button>
                    ))}
                </div>
              </>
            ) : (
              <p>Select a lesson to view its content.</p>
            )}
          </div>
        </div>
      </div>
      <audio ref={audioRef} className="hidden" />
    </div>
  );
}
