import { default as Lesson1 } from "./lesson-1.json"
import { default as Lesson2 } from "./lesson-2.json"
import { default as Lesson3 } from "./lesson-3.json"
import { default as Lesson4 } from "./lesson-4.json"
import { default as Lesson5 } from "./lesson-5.json"
import { default as Lesson6 } from "./lesson-6.json"
import { default as Lesson7 } from "./lesson-7.json"
import { default as Lesson8 } from "./lesson-8.json"
import { default as Lesson9 } from "./lesson-9.json"
import { default as Lesson10 } from "./lesson-10.json"
import { default as Lesson11 } from "./lesson-11.json"
import { default as Lesson12 } from "./lesson-12.json"
import { default as Lesson13 } from "./lesson-13.json"
import { default as Lesson14 } from "./lesson-14.json"
import { default as Lesson15 } from "./lesson-15.json"
import { default as Lesson16 } from "./lesson-16.json"
import { default as Lesson17 } from "./lesson-17.json"
import { default as Lesson18 } from "./lesson-18.json"
import { default as Lesson19 } from "./lesson-19.json"
import { default as Lesson20 } from "./lesson-20.json"
import { default as Lesson21 } from "./lesson-21.json"
import { default as Lesson22 } from "./lesson-22.json"
import { default as Lesson23 } from "./lesson-23.json"
import { default as Lesson24 } from "./lesson-24.json"
import { default as Lesson25 } from "./lesson-25.json"
import { default as Lesson26 } from "./lesson-26.json"
import { default as Lesson27 } from "./lesson-27.json"
import { default as Lesson28 } from "./lesson-28.json"
import { default as Lesson29 } from "./lesson-29.json"
import { default as Lesson30 } from "./lesson-30.json"
import { default as Lesson31 } from "./lesson-31.json"
import { default as Lesson32 } from "./lesson-32.json"
import { default as Lesson33 } from "./lesson-33.json"
import { default as Lesson34 } from "./lesson-34.json"
import { default as Lesson35 } from "./lesson-35.json"
import { default as Lesson36 } from "./lesson-36.json"
import { default as Lesson37 } from "./lesson-37.json"
import { default as Lesson38 } from "./lesson-38.json"
import { default as Lesson39 } from "./lesson-39.json"
import { default as Lesson40 } from "./lesson-40.json"
import { default as Lesson41 } from "./lesson-41.json"
import { default as Lesson42 } from "./lesson-42.json"
import { default as Lesson43 } from "./lesson-43.json"
import { default as Lesson44 } from "./lesson-44.json"
import { default as Lesson45 } from "./lesson-45.json"
import { default as Lesson46 } from "./lesson-46.json"
import { default as Lesson47 } from "./lesson-47.json"
import { default as Lesson48 } from "./lesson-48.json"
import { default as Lesson49 } from "./lesson-49.json"
import { default as Lesson50 } from "./lesson-50.json"
export const ms = {
    "audioPrefix": "msct",
    days: [...Lesson1, ...Lesson2, ...Lesson3, ...Lesson4, ...Lesson5, ...Lesson6, ...Lesson7, ...Lesson8, ...Lesson9, ...Lesson10, ...Lesson11, ...Lesson12, ...Lesson13, ...Lesson14, ...Lesson15, ...Lesson16, ...Lesson17, ...Lesson18, ...Lesson19, ...Lesson20, ...Lesson21, ...Lesson22, ...Lesson23, ...Lesson24, ...Lesson25, ...Lesson26, ...Lesson27, ...Lesson28, ...Lesson29, ...Lesson30, ...Lesson31, ...Lesson32, ...Lesson33, ...Lesson34, ...Lesson35, ...Lesson36, ...Lesson37, ...Lesson38, ...Lesson39, ...Lesson40, ...Lesson41, ...Lesson42, ...Lesson43, ...Lesson44,
    ...Lesson45, ...Lesson46, ...Lesson47, ...Lesson48, ...Lesson49, ...Lesson50],


    "timer": {
        "day4": {
            "rio": 30
        },
        "day3": {
            "cOpts": 60 * 3
        }
    }
}