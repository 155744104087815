import { useCustom, useGetIdentity, useNavigation } from "@pankod/refine-core";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  DataGrid,
  FormControl,
  GridColumns,
  InputLabel,
  LoadingButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@pankod/refine-mui";
import { apiUrl } from "const/constants";
import { ReactNode, forwardRef, useState } from "react";
import { Icons } from "utils/icons";
import { Report } from "utils/type";
import { exportAsPdf, generateExcelFile } from "utils/utils";

export const PrincipalDashboard = ({ id }: { id?: string }) => {
  const { data } = useCustom<Report>({
    url: `${apiUrl}/admin/notification/generalReport${id ? `/${id}` : ""}`,
    method: "get",
  });
  const { show } = useNavigation();
  const { data: user } = useGetIdentity();
  const teacherMap = Object.keys(data?.data.teacherSessionsMap ?? {}).map(
    (key) => {
      const d = data?.data.teacherSessionsMap[key]!;
      return {
        ...d,
        id: key,
        belowMedianCount: d.belowMedian.length,
        aboveMedianCount: d.aboveMedian.length,
        aboveMedianActivityCount: d.aboveMedianActivity.length,
        belowMedianActivityCount: d.belowMedianActivity.length,
      };
    }
  );
  type TeacherMap = (typeof teacherMap)[0];
  type InActiveStudent = Report["inActiveStudents"][0];
  const inActiveStudents = data?.data.inActiveStudents ?? [];
  const teacherColumns: GridColumns<TeacherMap> = [
    {
      field: "firstName",
      headerName: "Full Name",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const teacher = params.row;
        return (
          <>
            <Avatar src={""} alt={teacher?.firstName} />
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {teacher?.firstName} {teacher?.lastName}
            </Typography>
          </>
        );
      },
    },

    {
      field: "studentCount",
      headerName: "Total Enrolled Students",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        return (
          <>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {params.row.studentCount}
            </Typography>
          </>
        );
      },
    },
    {
      field: "totalMinutes",
      headerName: "Average Minutes",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const studentsCount = params.row.studentCount;
        return (
          <>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {studentsCount
                ? Math.floor(params.row.totalMinutes / studentsCount)
                : 0}{" "}
              mins
            </Typography>
          </>
        );
      },
    },

    {
      field: "LessonMedian",
      headerName: "Median Fluency Lesson Progress",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        return (
          <>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {params.row.LessonMedian ?? "-"}
            </Typography>
          </>
        );
      },
    },
    {
      field: "criticalMedian",
      headerName: "Median Critical Thinking Progress",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        return (
          <>
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {params.row.criticalMedian ?? "-"}
            </Typography>
          </>
        );
      },
    },
  ];

  const studentColumn: GridColumns<InActiveStudent> = [
    {
      field: "firstName",
      headerName: "Full Name",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const student = params.row;
        return (
          <>
            <Avatar src={""} alt={student.firstName} />
            <Typography variant="subtitle2" sx={{ ml: 1 }}>
              {student.firstName} {student.lastName}
            </Typography>
          </>
        );
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const student = params.row;
        return (
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            {student.grade}
          </Typography>
        );
      },
    },
    {
      field: "teacher",
      headerName: "Teacher",
      minWidth: 200,
      flex: 1,
      renderCell(params) {
        const student = params.row;
        const teacher = teacherMap.find((t) => t.id === student.teacherId);
        return (
          <Typography variant="subtitle2" sx={{ ml: 1 }}>
            {teacher?.firstName} {teacher?.lastName}
          </Typography>
        );
      },
    },
  ];

  const [exportAs, setExportAs] = useState("excel");

  const studentNotSignedInLastSevenDays =
    data?.data.studentsNotSignedUpLastSevenDays ?? [];
  async function exportReport() {
    if (exportAs === "pdf") {
      await exportAsPdf(
        "#report",
        `${new Date().toDateString()} Read 21 Report`
      );
    } else {
      generateExcelFile([
        {
          data: [
            [data?.data.schoolStatistics.totalMinutes ?? 0],
            [data?.data.schoolStatistics.studentCount ?? 0],
            [data?.data.averageMinutes ?? 0],
            [data?.data.medianProgress ?? 0],
          ],
          column: [
            "Total Minutes",
            "Total Active Students",
            "Average Minute Per Student",
            "Median Fluency Lesson Progress",
            "Median Critical Thinking Lesson Progress",
          ],
          sectionHeader: "Summary Report",
        },
        {
          data: teacherMap.map((row) => [
            row.firstName,
            row.lastName,
            row.studentCount,
            row.aboveMedianCount,
            row.belowMedianCount,
            row.aboveMedianActivityCount,
            row.belowMedianActivityCount,
          ]),
          column: [
            "First Name",
            "Last Name",
            "Total Students",
            "Above Median On Fluency",
            "Below Median on Fluency",
            "Above Median On Critical Thinking",
            "Below Median on Critical Thinking",
          ],
          sectionHeader: "Teachers",
        },
        {
          data: inActiveStudents.map((row) => [
            row.firstName,
            row.lastName,
            row.grade,
            `${teacherMap.find((t) => t.id === row.teacherId)?.firstName} ${
              teacherMap.find((t) => t.id === row.teacherId)?.lastName
            }`,
          ]),
          column: ["First Name", "Last Name", "Grade", "Teacher"],
          sectionHeader: "Inactive Students",
        },
        {
          data: studentNotSignedInLastSevenDays.map((row) => [
            row.firstName,
            row.lastName,
            row.grade,
            `${teacherMap.find((t) => t.id === row.teacherId)?.firstName} ${
              teacherMap.find((t) => t.id === row.teacherId)?.lastName
            }`,
          ]),
          column: ["First Name", "Last Name", "Grade", "Teacher"],
          sectionHeader: "Student Not Signed IN Last Seven Days",
        },
      ]);
    }
  }
  return (
    <div style={{ color: "black" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h2>
          Welcome, {user.firstName} {user.lastName}
        </h2>
        <Stack direction="row">
          <FormControl>
            <InputLabel id="export">Export</InputLabel>
            <Select
              labelId="export"
              label="Export"
              value={exportAs}
              onChange={(e) => setExportAs(e.target.value)}
            >
              <MenuItem value={"pdf"}>PDF</MenuItem>
              <MenuItem value={"excel"}>Excel</MenuItem>
            </Select>
          </FormControl>
          <Button
            onClick={() => {
              exportReport();
            }}
          >
            {exportAs === "pdf" ? "PDF" : "Export"}
          </Button>
        </Stack>
      </Stack>
      <div id="report">
        <Stack
          justifyContent="space-between"
          direction="row"
          flexWrap="wrap"
          gap="12px"
        >
          {/* <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>Total Minutes</p>
                <Icons.Timer />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                {data?.data.schoolStatistics.totalMinutes}
                <span style={{ fontSize: "12px", paddingLeft: "4px" }}>
                  mins
                </span>
              </p>
            </CardContent>
          </Card> */}
          <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>Total Active Students</p>
                <Icons.Users />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                {data?.data.schoolStatistics.studentCount}{" "}
                <span style={{ fontSize: "16px" }}>students</span>
              </p>
            </CardContent>
          </Card>

          <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Average Minutes Per Student
                </p>
                <Icons.Timer />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                {data?.data.averageMinutes
                  ? data.data.averageMinutes.toFixed(0)
                  : 0}
                <span style={{ fontSize: "12px", paddingLeft: "4px" }}>
                  mins
                </span>
              </p>
            </CardContent>
          </Card>

          <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Median Fluency Lesson Progress
                </p>
                <Icons.Progress />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                Lesson {Math.floor(data?.data.medianProgress ?? 0)}
              </p>
            </CardContent>
          </Card>
          <Card style={{ width: "24%", backgroundColor: "#f0f0f0" }}>
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="12px"
              >
                <p style={{ fontWeight: "bold" }}>
                  Median Critical Thinking Lesson Progress
                </p>
                <Icons.Activity />
              </Stack>
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                Lesson {Math.floor(data?.data.medianActivity ?? 0)}
              </p>
            </CardContent>
          </Card>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <h3>Teachers</h3>
        </Stack>
        <DataGrid
          columns={teacherColumns}
          rows={teacherMap}
          autoHeight
          rowsPerPageOptions={[10, 20, 50, 100]}
          onCellClick={(cell) => {
            show("teacher", cell.row.id);
          }}
        />
        <h3>Students Not Signed In Last Seven Days</h3>
        <DataGrid
          columns={studentColumn}
          rows={studentNotSignedInLastSevenDays}
          autoHeight
          rowsPerPageOptions={[10, 20, 50, 100]}
          onCellClick={(cell) => {
            show("student", cell.row.id);
          }}
        />
        <h3>In Active Students</h3>
        <DataGrid
          columns={studentColumn}
          rows={inActiveStudents}
          autoHeight
          rowsPerPageOptions={[10, 20, 50, 100]}
          onCellClick={(cell) => {
            show("student", cell.row.id);
          }}
        />
      </div>
    </div>
  );
};

const PDF = forwardRef(({ children }: { children: ReactNode }) => {
  return <div>{children}</div>;
});
