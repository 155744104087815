"use client";

import { useCustom, useQuery } from "@pankod/refine-core";
import {
  Button,
  GridColumns,
  GridFilterModel,
  List,
  Stack,
  useDataGrid,
} from "@pankod/refine-mui";
import { DataGrid } from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { apiUrl } from "const/constants";
import { useState } from "react";
import { DateRangePicker } from "react-dates";
import { $fetch } from "utils/fetch";

function convertDate(date: any) {
  return `${new Date(date).getFullYear()}-${
    new Date(date).getMonth() + 1
  }-${new Date(date).getDate()}`;
}

export const LogReport = () => {
  type Response = {
    lessonId: number;
    textId: string;
    total_count: string;
    week_number: string;
    text: { text: string };
  };
  const [searchDateRange, setSearchDateRange] = useState<any[]>([]);
  // edited by bek
  // const [countResults, setCountResults] = useState<Record<string, number>>({});
  const [countResults, setCountResults] = useState<Record<string, Record<string, number>>>({});

  const startDate = convertDate(searchDateRange[0]);
  const endDate = convertDate(searchDateRange[1]);
  const { data } = useQuery({
    queryKey: ["admin/countCorrectedLogs", JSON.stringify(searchDateRange)],
    queryFn: async () => {
      const query =
        searchDateRange[0] && searchDateRange[1]
          ? {
              start_date: !searchDateRange[0] ? undefined : startDate,
              end_date: !searchDateRange[1] ? undefined : endDate,
            }
          : {};
      const res = await $fetch<Response[]>("admin/countCorrectedLogs", {
        query,
      });
      return res.data!;
    },
  });

  const handleGetCount = async (textId: string, lessonId: number) => {
    const date = new Date();

    const aWeekAgo = new Date(date.setDate(date.getDate() - 7));
    const res = await $fetch<{
      total: number;
      correct: number;
    }>(`/admin/countCorrectedLogs/count`, {
      query: {
        lessonId: lessonId,
        textId: textId,
        start_date: searchDateRange[0] ? startDate : convertDate(aWeekAgo),
        end_date: searchDateRange[1] ? endDate : convertDate(new Date()),
      },
    });
    // edited by bek
    // setCountResults((prev) => ({ ...prev, [textId]: res.data?.correct || 0 }));
    setCountResults((prev) => ({ ...prev, [textId]:{[lessonId]: res.data?.correct|| 0}   }));
  };

  const columns: GridColumns<Response> = [
    {
      field: "lessonId",
      headerName: "Lesson ID",
      flex: 1,
      valueGetter(params) {
        return params.row.lessonId.toString();
      },
    },
    {
      field: "textId",
      headerName: "Key",
      flex: 1,
    },
    {
      field: "total_count",
      headerName: "Updated Count",
      valueGetter(params) {
        return parseInt(params.row.total_count);
      },
      sortable: true,
      flex: 1,
    },
    {
      field: "text[text]",
      headerName: "Text",
      flex: 1,
      renderCell: ({ row }) => {
        return <span>{row.text?.text || "-"}</span>;
      },
      valueGetter(params) {
        return params.row.text.text;
      },
    },
    {
      field: "week_number",
      headerName: "Week Number",
      flex: 1,
      renderCell: (params) => {
        const value = (params.value.toString() as string).substring(4, 6);
        return <p>{value}</p>;
      },
    },
    {
      field: "getCount",
      headerName: "Get Count",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {countResults?.[params.row.textId] ? null : (
              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  handleGetCount(params.row.textId, params.row.lessonId)
                }
              >
                Get Count
              </Button>
            )}

            {
              // edited by bek
            // countResults[params.row.textId] !== undefined && (
            //   <span>{countResults[params.row.textId]}</span>
            // )
            }
             {countResults[params.row.textId]?.[params.row.lessonId] !== undefined && (
              <span>{countResults[params.row.textId]?.[params.row.lessonId]}</span>
            )}
          </Stack>
        );
      },
    },
  ];

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      { columnField: "columnName", operatorValue: "contains", value: "" },
    ],
  });

  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div>
      <div style={{ marginTop: "12px" }} id="export">
        <List
          title="Manual Correction Logs"
          headerButtons={() => {
            return (
              <Stack direction="row" spacing={2}>
                <ExportAs
                  id="export"
                  getExportName={() => "Correction Log"}
                  getRows={() => [
                    ["lesson Id", "Key", "Count", "Week Number"],
                    ...(data?.map((d) => [
                      d.lessonId,
                      d.textId,
                      d.total_count,
                      (d.week_number.toString() as string).substring(4, 6),
                    ]) ?? []),
                  ]}
                />
              </Stack>
            );
          }}
        >
          <div className="my-2 flex flex-col gap-2 w-max">
            <p className="font-bold">Select Date Range</p>
            <DateRangePicker
              startDate={searchDateRange[0]}
              startDateId="uStId"
              endDate={searchDateRange[1]}
              endDateId="uEnD"
              disabled={false}
              isOutsideRange={() => false}
              onDatesChange={({
                startDate,
                endDate,
              }: {
                startDate: any;
                endDate: any;
              }) => setSearchDateRange([startDate, endDate])}
              focusedInput={focusedInput}
              onFocusChange={(inp: any) => {
                setFocusedInput(inp);
              }}
            />
          </div>

          <DataGrid
            getRowId={(row) => JSON.stringify(row)}
            rows={data ?? []}
            columns={columns}
            autoHeight
            filterModel={filterModel}
            onFilterModelChange={(model) => {
              setFilterModel(model);
            }}
            filterMode="client"
            rowsPerPageOptions={[10, 20, 50, 100]}
            density="comfortable"
            sx={{
              "& .MuiDataGrid-cell:hover": {
                cursor: "pointer",
              },
            }}
          />
        </List>
      </div>
    </div>
  );
};
