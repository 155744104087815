import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@pankod/refine-mui";
import { useState } from "react";
import { exportAsPdf, exportAsPdfCropped, generateExcelFile, s2ab } from "utils/utils";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

export const ExportAs = ({
  id,
  getExportName,
  getRows,
  centerRow,
  multiplePages,
  pageCount,
  withTitle = false,
}: {
  id: string;
  getExportName: () => string;
  getRows: () => (string | number)[][];
  centerRow?: boolean;
  multiplePages?: boolean;
  pageCount?: number;
  withTitle?: boolean;
}) => {
  const [exportAs, setExportAs] = useState<"pdf" | "excel">("pdf");
  async function exportReport() {
    if (exportAs === "pdf") {
      if(withTitle){

        await exportAsPdfCropped(
          `#${id}`,
          `${getExportName()} (${new Date().toLocaleDateString()})`,
          multiplePages,
          pageCount
        );
      }else{
        await exportAsPdf(
          `#${id}`,
          `${getExportName()} (${new Date().toLocaleDateString()})`,
          multiplePages,
          pageCount
        );
      }
    } else {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      const rows = getRows();
      rows.forEach((row, rowIndex) => {
        row.forEach((cell, cellIndex) => {
          const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
          excelCell.value = cell;
          if (cellIndex === 0) {
            excelCell.alignment = { horizontal: centerRow ? "center" : "left" };
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${getExportName()} ${new Date().toDateString()}.xlsx`);
    }
  }
  return (
    <Stack direction="row" minWidth="max-content">
      <FormControl>
        <InputLabel id="export">Export</InputLabel>
        <Select
          labelId="export"
          label="Export"
          value={exportAs}
          onChange={(e) => setExportAs(e.target.value as "pdf")}
        >
          <MenuItem value={"pdf"}>PDF</MenuItem>
          <MenuItem value={"excel"}>Excel</MenuItem>
        </Select>
      </FormControl>
      <Button
        onClick={() => {
          exportReport();
        }}
      >
        {exportAs === "pdf" ? "Print" : "Export"}
      </Button>
    </Stack>
  );
};
