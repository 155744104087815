import {
  AdminPanelSettings,
  AssignmentInd,
  CalendarViewDayOutlined,
  ChildCare,
  ChildCareOutlined,
  ClassOutlined,
  Groups2,
  LocalActivity,
  LocalActivityOutlined,
  NextWeekOutlined,
  OnlinePrediction,
  PeopleOutlined,
  PersonOutlined,
  PlayLessonOutlined,
  Report,
  RoomOutlined,
  SchoolOutlined,
  SchoolRounded,
  Scoreboard,
  Summarize,
  TimerOutlined,
  TrackChanges,
  WeekendOutlined,
} from "@mui/icons-material";
import { Refine } from "@pankod/refine-core";
import {
  CssBaseline,
  ErrorComponent,
  GlobalStyles,
  Layout,
  LightTheme,
  notificationProvider,
  ReadyPage,
  RefineSnackbarProvider,
  ThemeProvider,
} from "@pankod/refine-mui";
import "./styles/global.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "authProvider";
import { LoginPage } from "pages/login";
import { AxiosInstance } from "@pankod/refine-simple-rest/node_modules/axios/index";
import { Header } from "components";
import { createAxios } from "utils/customAxios";
import { PrincipalCreate, PrincipalList, PrincipalShow } from "pages/principal";
import { SchoolCreate, SchoolEdit, SchoolList, SchoolShow } from "pages/school";
import { TeacherCreate, TeacherList, TeacherShow } from "pages/teacher";
import { GroupCreate, GroupList, GroupShow } from "pages/teacherGroup";
import { SubGroupCreate, SubGroupList, SubGroupShow } from "pages/teacherSubGroup";
import { StudentGroupCreate, StudentGroupList, StudentGroupShow } from "pages/studentGroup";
import { StudentCreate, StudentList, StudentShow } from "pages/student";
import { UserEdit } from "pages/user";

import { Title } from "components/title";
import {
  LessonReportList,
  StudentReportList,
  ClassReportList,
  StudentReportShow,
  ClassReportShow,
  LessonReportShow,
} from "pages/fluencyReport";
import { ConfirmProvider } from "material-ui-confirm";
import { acProvider } from "acProvider";
import WelcomeHomePage from "./pages/dashboard.js";
import { LessonManageShow } from "./pages/lesson";
import {
  TimeActivityCTReport,
  TimeActivityFlReport,
} from "./pages/timeActivity/report";
import {
  CTClassReportList,
  CTClassReportShow,
  CTLessonReportList,
  CTLessonReportShow,
  CTStudentReportList,
  CTStudentReportShow,
} from "./pages/ctReport";
import { SchoolReport } from "pages/school/report";
import { LogReport } from "pages/log-report/correction-log";
import { ReportCard } from "components/report-card";
import { ParentList } from "pages/parent/list";
import { ParentCreate } from "pages/parent/create";
import { ParentShow } from "pages/parent/show";
import { PDF } from "components/report-card/card-pdf";
import { CheckpointStudentReportList } from "pages/checkpoint/list/student";
import { IndividualCheckpointShow } from "pages/checkpoint/show/student";
import { Checkpoint1to3List } from "pages/checkpoint/list/cp1-3";
import { Checkpoint1to3Show } from "pages/checkpoint/show/cp1-3";
import { Contact } from "pages/contact/page";
import { TeacherReportShow } from "pages/school-report/teacher-report/show";
import { TeacherReportList } from "pages/school-report/teacher-report/list";
import { GradeLevelReport } from "pages/school-report/grade-report";
import { DownloadLog } from "pages/log-report/download-log";
import { AssignablesList } from "pages/checkpoint/list/assignables";
import { AssignablesShow } from "pages/checkpoint/show/assignables";
import { DyslexiaReportList } from "pages/fluencyReport/list/dsylexia";
import { DyslexiaReportShow } from "pages/fluencyReport/show/dsylexia";
import ResourcePage from "pages/resource";
import { ChClassReportList } from "pages/checkpoint/list/class";
import { ChClassShow } from "pages/checkpoint/show/class";

function App() {
  return (
    <ConfirmProvider>
      <ThemeProvider
        theme={{
          ...LightTheme,
          palette: {
            ...LightTheme.palette,
            secondary: {
              main: "#0057FF",
            },
          },
        }}
      >
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <RefineSnackbarProvider>
          <Refine
            notificationProvider={notificationProvider}
            Layout={Layout}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            routerProvider={{
              ...routerProvider,
              routes: [
                { path: "/school/report", element: <SchoolReport /> },
                // { path: "/school/report", element: <ChClassReportList /> },
                {
                  path: "/resources",
                  element: <ResourcePage />,
                },
              ],
            }}
            Header={Header}
            LoginPage={LoginPage}
            authProvider={authProvider}
            Title={Title}
            DashboardPage={WelcomeHomePage}
            dataProvider={dataProvider(
              `${process.env.REACT_APP_BACKEND}admin`,
              createAxios() as AxiosInstance
            )}
            resources={[
              {
                name: "school",
                list: SchoolList,
                create: SchoolCreate,
                show: SchoolShow,
                edit: SchoolEdit,
                icon: <SchoolOutlined />,
              },
              {
                name: "schoolReport",
                icon: <SchoolRounded />,
                options: {
                  label: "School Reports",
                },
              },

              {
                name: "teacherReport",
                icon: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.4em"
                    height="1.4em"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="currentColor"
                      d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h13.39a8 8 0 0 0 7.23-4.57a48 48 0 0 1 86.76 0a8 8 0 0 0 7.23 4.57H216a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16M104 168a32 32 0 1 1 32-32a32 32 0 0 1-32 32m112 32h-56.57a64 64 0 0 0-13.16-16H192a8 8 0 0 0 8-8V80a8 8 0 0 0-8-8H64a8 8 0 0 0-8 8v96a8 8 0 0 0 6 7.75A63.7 63.7 0 0 0 48.57 200H40V56h176Z"
                    ></path>
                  </svg>
                ),
                parentName: "schoolReport",
                options: {
                  label: "Teacher Report",
                },
                show: TeacherReportShow,
                list: TeacherReportList,
              },
              {
                name: "gradeLevelReport",
                icon: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.4em"
                    height="1.4em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M23 2H1a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m-1 18h-2v-1h-5v1H2V4h20zM10.29 9.71A1.71 1.71 0 0 1 12 8c.95 0 1.71.77 1.71 1.71c0 .95-.76 1.72-1.71 1.72s-1.71-.77-1.71-1.72m-4.58 1.58c0-.71.58-1.29 1.29-1.29a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28S5.71 12 5.71 11.29m10 0A1.29 1.29 0 0 1 17 10a1.29 1.29 0 0 1 1.29 1.29c0 .71-.58 1.28-1.29 1.28s-1.29-.57-1.29-1.28M20 15.14V16H4v-.86c0-.94 1.55-1.71 3-1.71c.55 0 1.11.11 1.6.3c.75-.69 2.1-1.16 3.4-1.16s2.65.47 3.4 1.16c.49-.19 1.05-.3 1.6-.3c1.45 0 3 .77 3 1.71"
                    ></path>
                  </svg>
                ),
                parentName: "schoolReport",
                options: {
                  label: "Grade Level Report",
                },
                show: GradeLevelReport,
                list: GradeLevelReport,
              },

              {
                name: "dyslexiaReport",
                parentName: "schoolReport",
                icon: <PeopleOutlined />,
                list: DyslexiaReportList,
                show: DyslexiaReportShow,
                options: {
                  label: "Dyslexia Report",
                },
              },

              {
                name: "principal",
                list: PrincipalList,
                create: PrincipalCreate,
                show: PrincipalShow,
                icon: <AdminPanelSettings />,
                options: {
                  label: "School Admins",
                },
              },
              {
                name: "parent",
                list: ParentList,
                create: ParentCreate,
                show: ParentShow,
                icon: <PeopleOutlined />,
              },
              {
                name: "teacher",
                icon: <Groups2 />,
                list: TeacherList,
                create: TeacherCreate,
                show: TeacherShow,
              },
              {
                name: "teachergroup",
                icon: <Groups2 />,
                list: GroupList,
                create: GroupCreate,
                show: GroupShow,
                options:{
                  label: "Classes"
                }
              },

              {
                name: "teacherGroup/subgroups",
                icon: <Groups2 />,
                list: SubGroupList,
                create: SubGroupCreate,
                show: SubGroupShow,
                options: {
                  label: "Group"
                }
              },

              {
                name: "student",
                icon: <ChildCare />,
                list: StudentList,
                create: StudentCreate,
                show: StudentShow,
              },

              {
                name: "group",
                icon: <Groups2 />,
                list: StudentGroupList,
                // create: StudentGroupCreate,
                show: StudentGroupShow,
                options:{
                  label: "Classes",   
                }
              },

              {
                name: "fluencyReport",
                icon: <Summarize />,
                options: {
                  label: "Fluency",
                },
              },

              //checkpoint
              {
                name: "checkpoint-report",
                icon: <Summarize />,
                options: {
                  label: "Checkpoint",
                },
              },
                        
              {
                name: "classReport/aggregate",
                parentName: "checkpoint-report",
                icon: <PeopleOutlined />,
                list: ChClassReportList,
                show: ChClassShow,
                options: {
                  label: "Overall Progress Report",
                },
              },

              {
                name: "chreport",
                parentName: "checkpoint-report",
                icon: <PlayLessonOutlined />,
                list: CheckpointStudentReportList,
                show: IndividualCheckpointShow,
                options: {
                  label: "Individual Report",
                },
              },
              {
                name: "lessonreport",
                parentName: "checkpoint-report",
                icon: <TimerOutlined />,
                list: Checkpoint1to3List,
                show: Checkpoint1to3Show,
                options: {
                  label: "CP 1 - 3",
                },
              },

              {
                name: "assignable",
                parentName: "checkpoint-report",
                icon: <ChildCareOutlined />,
                list: AssignablesList,
                show: AssignablesShow,
                options: {
                  label: "Assignables",
                },
              },

              ////

              {
                name: "lesson",
                icon: <PlayLessonOutlined />,
                list: LessonManageShow,
                options: {
                  label: "Manage Fluency",
                },
                parentName: "fluencyReport",
              },
              {
                name: "flActivity",
                icon: <TimerOutlined />,
                list: TimeActivityFlReport,
                options: {
                  label: "Time / Activity",
                },
                parentName: "fluencyReport",
              },
              {
                name: "lessonReport",
                parentName: "fluencyReport",
                icon: <PlayLessonOutlined />,
                list: LessonReportList,
                show: LessonReportShow,
                options: {
                  label: "Lesson Reports",
                },
              },
              {
                name: "studentReport",
                parentName: "fluencyReport",
                icon: <ChildCareOutlined />,
                list: StudentReportList,
                show: StudentReportShow,
                options: {
                  label: "Individual Progress",
                },
              },

              {
                name: "report",
                icon: <Summarize />,
                options: {
                  label: "Reports",
                },
              },

              //report
              {
                name: "studentReportCT",
                icon: <ChildCareOutlined />,
                list: CTStudentReportList,
                show: CTStudentReportShow,
                options: {
                  label: "CT Report",
                },
                parentName: "report",
              },
              {
                name: "studentReport",
                icon: <ChildCareOutlined />,
                list: StudentReportList,
                show: StudentReportShow,
                options: {
                  label: "Fluency Report",
                },
                parentName: "report",
              },

              ///

              {
                name: "classReport",
                parentName: "fluencyReport",
                icon: <PeopleOutlined />,
                list: ClassReportList,
                show: ClassReportShow,
                options: {
                  label: "Class Progress",
                },
              },

              {
                name: "criticalReport",
                icon: <Summarize />,
                options: {
                  label: "Critical Thinking",
                },
              },
              {
                name: "ctActivity",
                icon: <TimerOutlined />,
                list: TimeActivityCTReport,
                options: {
                  label: "Time / Activity",
                },
                parentName: "criticalReport",
              },
              {
                name: "lessonReportCT",
                parentName: "criticalReport",
                icon: <PlayLessonOutlined />,
                list: CTLessonReportList,
                show: CTLessonReportShow,
                options: {
                  label: "Lesson Reports",
                },
              },
              {
                name: "studentReportCT",
                parentName: "criticalReport",
                icon: <ChildCareOutlined />,
                list: CTStudentReportList,
                show: CTStudentReportShow,
                options: {
                  label: "Individual Report",
                },
              },

              {
                name: "classReportCT",
                parentName: "criticalReport",
                icon: <PeopleOutlined />,
                list: CTClassReportList,
                show: CTClassReportShow,
                options: {
                  label: "Class Progress",
                },
              },

              {
                name: "license",
                icon: <AssignmentInd />,
              },
              {
                name: "user",
                edit: UserEdit,
                icon: <PersonOutlined />,
              },
              {
                name: "archive",
                icon: <Summarize />,
                options: {
                  label: "Archive",
                },
              },

              {
                name: "log",
                icon: <Summarize />,
                options: {
                  label: "Log",
                },
              },

              {
                name: "correctionLogs",
                parentName: "log",
                icon: <Summarize />,
                options: {
                  label: "Correction Logs",
                },
                show: LogReport,
                // create: LogReport,
                list: LogReport,
              },
              {
                name: "downloadLogs",
                parentName: "log",
                icon: <Summarize />,
                options: {
                  label: "Download Logs",
                },
                show: DownloadLog,
                // create: LogReport,
                list: DownloadLog,
              },

              {
                name: "student",
                parentName: "archive",
                icon: <ChildCare />,
                list: StudentList,
                create: StudentCreate,
                show: StudentShow,
              },
              {
                name: "contact",
                icon: (
                  <div className="flex items-center gap-2 text-amber-300 px-1 py-1 rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11.75 19h-.25q-3.55 0-6.025-2.475T3 10.5t2.475-6.025T11.5 2q1.775 0 3.313.662t2.7 1.825t1.824 2.7T20 10.5q0 3.35-1.888 6.225t-4.762 4.5q-.25.125-.5.138t-.45-.113t-.35-.325t-.175-.475zm-.275-3.025q.425 0 .725-.3t.3-.725t-.3-.725t-.725-.3t-.725.3t-.3.725t.3.725t.725.3M9.3 8.375q.275.125.55.013t.45-.363q.225-.3.525-.463T11.5 7.4q.6 0 .975.337t.375.863q0 .325-.187.65t-.663.8q-.625.55-.925 1.038t-.3.987q0 .3.213.513t.512.212t.5-.225t.3-.525q.125-.425.45-.775t.6-.625q.525-.525.788-1.05t.262-1.05q0-1.15-.788-1.85T11.5 6q-.8 0-1.475.388t-1.1 1.062q-.15.275-.038.538t.413.387"
                      ></path>
                    </svg>
                    <p className="text-sm">Contact Support</p>
                  </div>
                ),
                options: {
                  label: "",
                },
                list: Contact,
              },
            ]}
            accessControlProvider={acProvider}
          />
        </RefineSnackbarProvider>
      </ThemeProvider>
    </ConfirmProvider>
  );
}

export default App;
