import { default as Lesson1 } from "./lesson-1.json"
import { default as Lesson2 } from "./lesson-2.json"
import { default as Lesson3 } from "./lesson-3.json"
import { default as Lesson4 } from "./lesson-4.json"
import { default as Lesson5 } from "./lesson-5.json"
import { default as Lesson6 } from "./lesson-6.json"
import { default as Lesson7 } from "./lesson-7.json"
import { default as Lesson8 } from "./lesson-8.json"
import { default as Lesson9 } from "./lesson-9.json"
import { default as Lesson10 } from "./lesson-10.json"
import { default as Lesson11 } from "./lesson-11.json"
import { default as Lesson12 } from "./lesson-12.json"
import { default as Lesson13 } from "./lesson-13.json"
import { default as Lesson14 } from "./lesson-14.json"
import { default as Lesson15 } from "./lesson-15.json"
import { default as Lesson16 } from "./lesson-16.json"
import { default as Lesson17 } from "./lesson-17.json"
import { default as Lesson18 } from "./lesson-18.json"
import { default as Lesson19 } from "./lesson-19.json"
import { default as Lesson20 } from "./lesson-20.json"

export const g5 = {
    "audioPrefix": "g5ct",
    days: [
        ...Lesson1, ...Lesson2, ...Lesson3, ...Lesson4, ...Lesson5, ...Lesson6, ...Lesson7, ...Lesson8, ...Lesson9, ...Lesson10, ...Lesson11, ...Lesson12, ...Lesson13, ...Lesson14, ...Lesson15, ...Lesson16, ...Lesson17, ...Lesson18, ...Lesson19, ...Lesson20
    ].map(d => {
        if (d.type === "day3") {
            d.paragraph.breaks = d.paragraph.breaks.filter(b => b !== 0)
        }
        return d
    })
}