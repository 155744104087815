import React, { useEffect, useRef, useState } from "react";
import {
  useTranslate,
  IResourceComponentsProps,
  useDelete,
  useNavigation,
  useShow,
  useList,
  useCan,
} from "@pankod/refine-core";
import {
  Stack,
  Typography,
  Grid,
  Card,
  Button,
  useAutocomplete,
  Autocomplete,
  TextField,
} from "@pankod/refine-mui";
import { Edit, Close } from "@mui/icons-material";
import { Lessons } from "../../../const/lessons";
import { IPrincipal, ISchool, IStudent, ITeacher } from "interfaces";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { CTClassReportList } from "../list/class";
import { ExportAs } from "components/export";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// {
//     <Grid item xs={2} sm={4} md={4} key={index}>
//         <ReportCard index={index} onClick={(sc)=>{
//             push(`${showUrl("lessonReport", index+1)}?school=${sc?.id}`)
//         }}/>
//     </Grid>
// ))}

function genColor(seed: number) {
  return `hsl(${(seed * 72) % 360}, 95%, 35%)`;
}

export const CTClassReportShow: React.FC<IResourceComponentsProps> = () => {
  const { show, create, showUrl, push } = useNavigation();
  const t = useTranslate();
  const { mutate: mutateDelete } = useDelete();
  const { data: canShowTeacher } = useCan({
    action: "list",
    resource: "teacher",
  });

  const {
    queryResult: { data: studentDataL },
    showId,
  } = useShow();
  const [studentData, setStudentData] = useState<any>({ data: null });
  const chart = useRef<ChartJSOrUndefined<"bar">>();
  const [teacher, setTeacher] = useState<ITeacher | null>(null);
  const [sortingOrder, setSortingOrder] = useState("az");
  const labels = studentData?.data?.students.map(
    (stud: any) => `${stud.firstName} ${stud.lastName}`
  );
  useEffect(() => {
    if (studentDataL?.data) {
      setTeacher(studentDataL?.data?.teacher as ITeacher);
      console.log(studentDataL?.data.students.length, "studentData");
      setStudentData({
        data: {
          students: studentDataL?.data?.students.sort((a: any, b: any) => {
            return (
              (sortingOrder == "az" ? 1 : -1) *
              a.firstName.localeCompare(b.firstName)
            );
          }),
        },
      });
    }
  }, [studentDataL?.data, sortingOrder]);
  const { autocompleteProps } = useAutocomplete<ITeacher>({
    resource: "teacher",
  });

  const data = studentData?.data?.students!.map((student: any) =>
    student?.last_lesson ? `L${student?.last_lesson}` : null
  );

  return (
    <Grid
      container
      padding={4}
      gap={5}
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      <Card
        style={{
          padding: 20,
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Typography variant={"h5"} sx={{ margin: 0, fontWeight: "bold" }}>
          Class Progress Reports
        </Typography>

        {canShowTeacher?.can ? (
          <Autocomplete
            size="small"
            {...autocompleteProps}
            value={teacher}
            onChange={(_, value) => {
              setTeacher(value);
            }}
            getOptionLabel={(item) => {
              return `${item.lastName}, ${item.firstName} (${item.school?.name})`;
            }}
            isOptionEqualToValue={(option, value) =>
              value === undefined || option.id === value.id
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" required />
            )}
          />
        ) : (
          <>
            <div style={{ width: 300 }} />
          </>
        )}
        <div style={{ alignSelf: "flex-end" }}>
          {canShowTeacher?.can && (
            <Button
              style={{ alignSelf: "flex-end" }}
              onClick={() => {
                if (teacher?.id) {
                  push(`${showUrl("teacher", teacher?.id)}`);
                }
              }}
            >
              Open
            </Button>
          )}

          <Button
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              if (teacher?.id || !canShowTeacher?.can) {
                push(`${showUrl("classReportCT", teacher?.id || "me")}`);
              }
            }}
          >
            Generate
          </Button>
        </div>
      </Card>
      <Card sx={{ flex: 1, padding: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (sortingOrder == "az") {
                setSortingOrder("za");
              } else {
                setSortingOrder("az");
              }
            }}
            variant={"h6"}
          >
            {sortingOrder === "az" ? "A-Z ↓" : "A-Z ↑"}
          </Typography>

          <ExportAs
            id="graph"
            getExportName={() =>
              `${teacher?.lastName}, ${teacher?.firstName} (${teacher?.school?.name}) Report`
            }
            multiplePages
            getRows={() => {
              return [
                ["Student", "Progress"],
                ...studentData?.data?.students.map((student: any) => [
                  `${student.firstName} ${student.lastName}`,
                  student?.last_lesson
                    ? `L${student?.last_lesson}`
                    : "Not Started",
                ]),
              ];
            }}
          />
        </Stack>
        <div
          style={{
            position: "relative",
            overflow: "auto",
            width: "100%",
          }}
        >
          <Bar
            id="graph"
            //@ts-ignore
            ref={chart}
            onClick={function (e) {
              console.log(chart.current!!.scales);
              var xLabel = chart.current!!.scales["y"].getValueForPixel(
                e.nativeEvent.offsetY
              )!!;
              const student = studentData?.data?.students[xLabel]!!;
              push(showUrl("studentReport", student!!.id!!));
            }}
            height={500}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top" as const,
                },
                title: {
                  display: true,
                  text: "Class Progress Report",
                },
                decimation: {
                  enabled: false,
                },
              },
              indexAxis: "y",
              scales: {
                x: {
                  beginAtZero: true,
                  max: 40,
                  min: 1,
                  // title: {
                  //   display: true,
                  //   text: "Lesson",
                  // },
                  ticks: {
                    maxTicksLimit: 40,
                    callback(tickValue, index, ticks) {
                      return `L${tickValue}`;
                    },
                    stepSize: 1,
                  },
                  labels: data,
                  position: "top",
                },
              },
            }}
            data={{
              labels,
              datasets: [
                {
                  label: `Progress`,
                  data: studentData?.data?.students.map((student: any) =>
                    student?.last_lesson ? student?.last_lesson : null
                  ),

                  backgroundColor: "green",
                  //grouped: true,
                  barThickness: 10,
                },
              ],
            }}
            style={{ height: "100%", position: "relative" }}
          />
        </div>
      </Card>
    </Grid>
  );
};
