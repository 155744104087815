import React from "react";
import {
    IResourceComponentsProps,
    HttpError,
    useNavigation,
    useRouterContext,
    useNotification,
    useDataProvider
} from "@pankod/refine-core";
import {
    useForm
} from "@pankod/refine-react-hook-form";
import {
    FormLabel,
    Stack,
    Box,
    FormControl,
    OutlinedInput,
    FormHelperText,
    Create,
    useAutocomplete,
} from "@pankod/refine-mui";

import { IGroup } from "interfaces";

export const GroupCreate: React.FC<IResourceComponentsProps> = () => {
    const apiUrl = process.env.REACT_APP_BACKEND;
    const { useLocation } = useRouterContext();
    const dataProvider = useDataProvider();
    const {goBack} = useNavigation();
    const defaultDataProvider = dataProvider();
    /**
     * @type {Location}
     */
    const location = useLocation();
    const notification = useNotification();
    const { autocompleteProps } = useAutocomplete<IGroup>({
        resource: "teachergroup"
    });
    const {
        refineCore: { onFinish, formLoading },
        control,
        watch,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        saveButtonProps
    } = useForm<IGroup, HttpError, IGroup>({
        refineCoreProps: {
            redirect: false,
            onMutationSuccess(data, variables, context) {
                goBack()
            },
        }
    });



    return (
        <Create
            saveButtonProps={saveButtonProps}
        >
            <Stack>
                <Box
                    paddingX="50px"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingX: {
                            xs: 1,
                            md: 6,
                        },
                    }}
                >
                    <form onSubmit={handleSubmit(onFinish)}>
                       
                            <Stack flex={1} gap="10px" marginTop="10px">

                                <FormControl>
                                    <FormLabel required>
                                        Class Name
                                    </FormLabel>
                                    <OutlinedInput
                                        id="name"
                                        {...register("name", {
                                            required: "name is required",
                                        })}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.name && (
                                        <FormHelperText error>
                                            {errors.name.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Class Description
                                    </FormLabel>
                                    <OutlinedInput
                                        id="description"
                                        {...register("description")}
                                        style={{ height: "40px" }}
                                    />
                                    {errors.description && (
                                        <FormHelperText error>
                                            {errors.description.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                

                            </Stack>
                       
                    </form>
                </Box>
            </Stack>
        </Create>
    );
};