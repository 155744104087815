import { ms } from "./ms-ct";
import general from "./ctData.json";
import { g2 } from "./g2";
import { g3 } from "./g3";
import { g4 } from "./g4";
import { g5 } from "./g5";

const CT = {
  middle: ms,
  general: general,
  g2: g2,
  g3,
  g4,
  g5,
};

export function getGradeLevel(grade: number) {
  const middle = [6, 7, 8];
  return middle.includes(grade)
    ? "middle"
    : grade === 2
    ? "g2"
    : grade === 3
    ? "g3"
    : grade === 4
    ? "g4"
    : grade === 5
    ? "g5"
    : "general";
}

export const useCTLesson = (grade: number) => {
  const c = CT[getGradeLevel(grade)];
  return c;
};
