"use client";

import { useList, useRouterContext, useShow, useNavigation, useOne } from "@pankod/refine-core";
import { useMemo, useState } from "react";
import {
  Box,
  display,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@pankod/refine-mui";
import { ExportAs } from "components/export";
import { visuallyHidden } from "@mui/utils";

type Res = {
  id: string;
  grade: number;
  firstName: string;
  lastName: string;
  pretest: number;
  CP1: number;
  CP2: number;
  CP3: number;
  dyslexia: number;
  l21: number;

};


interface Teacher {
  id: string;
  firstName: string;
  lastName: string;
}

interface Data {
  id: string;
  grade: number;
  studentName: string;
  CP1: number;
  CP2: number;
  CP3: number;
  dyslexia: number;
  l21: number;
  pretest: number;

}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === undefined || b[orderBy] === null) return -1;
  if (a[orderBy] === undefined || a[orderBy] === null) return 1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | undefined },
  b: { [key in Key]: number | string | undefined }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "studentName",
    label: "Student Name",
    numeric: false,
  },
  {
    id: "pretest",
    label: "Pretest",
    numeric: true,
  },
  {
    id: "dyslexia",
    label: "Dyslexia",
    numeric: true,
  },
  {
    id: "CP1",
    label: "CP1",
    numeric: true,
  },
  {
    id: "CP2",
    label: "CP2",
    numeric: true,
  },
  {
    id: "CP3",
    label: "CP3",
    numeric: true,
  },
  {
    id: "l21",
    label: "L21",
    numeric: true,
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              fontWeight: "bold",
              fontSize: '18px'
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const ChClassShow: React.FC = () => {
  const { useLocation } = useRouterContext();
  const { showUrl, push } = useNavigation();
  const { showId } = useShow();
  const location = useLocation();
  const teacherId = Number.parseInt(showId?.toString() ?? "0");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("studentName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [search, setSearch] = useState<string>("");

  const { data: unsortedData } = useList<Res>({
    config: {
      filters: [
        {
          field: "school",
          operator: "eq",
          value: new URLSearchParams(location.search).get("school"),
        },
        {
          field: "teacherId",
          operator: "eq",
          value: showId,
        },
      ],
    },
    resource: `classReport/aggregate/${showId}`,
  });
const id = String(showId);
  const {  data, isLoading, isError } = useOne<Teacher>({
 
    resource: `user`, 
    id,
                       
});

 
  const processedData: Data[] = useMemo(() => {
    return (
      unsortedData?.data?.map((row) => ({
        id: row.id,
        grade: row.grade,
        studentName: `${row.firstName} ${row.lastName}`,
        pretest: row.pretest,
        dyslexia: row.dyslexia,
        CP1: row.CP1,
        CP2: row.CP2,
        CP3: row.CP3,
        l21: row.l21,
        
      })) || []
    );
  }, [unsortedData]);

  const grades: number[] = useMemo(
    ()=>{
      let grade: number[] = [];

    unsortedData?.data?.map((row)=> !grade.includes(row.grade)&&grade.push(row.grade));
    grade.sort((a, b) => a - b);
    return grade;
    },
    [unsortedData]
  );

  const visibleRows = useMemo(
    () =>
      stableSort(processedData, getComparator(order, orderBy)).filter((row) => {
        return String(row.studentName)
          .toLowerCase()
          .includes(search.toLowerCase());
      }),
    [order, orderBy, processedData, search]
  );

  return (
    <div style={{ margin: '20px 20px 0px 20px', height: "100%", width: "97%" }}>
      <h1 style={{fontSize:'40px', color:'black', fontWeight: 'bolder'}} className="text-center">{`${data?.data.firstName??''}'s Class`}</h1>
      <h1 style={{fontSize:'20px', color:'black', fontWeight: 'bolder'}} className="text-center">{`Grade Levels: (${grades.map((grade)=> `${grade}`)})`}</h1>
      <div className="flex items-center justify-end py-2 m-2">
        <ExportAs
          id="result"
          multiplePages
          getExportName={() => `${new Date().toLocaleDateString()}`}
          //@ts-ignore
          getRows={() => {
            return [
              [
                "Student Name",
                "Pretest",
                "Dyslexia",
                "CP1",
                "CP2",
                "CP3",
                "L21",
              ],
              ...processedData.map((row) => {
                return [
                  row.studentName,
                  row.pretest,
                  row.dyslexia,
                  row.CP1,
                  row.CP2,
                  row.CP3,
                  row.l21,
                ];
              }),
            ];
          } } withTitle={true}        />
      </div>
      <Input
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={"Type here to search"}
        style={{ margin: 10 }}
      />
      <div id="result">
        <div id="title" style={{display:'none'}}>

      <h1 style={{fontSize:'40px', color:'black', fontWeight: 'bolder'}} className="text-center">{`${data?.data.firstName??''}'s Class`}</h1>
      <h1 style={{fontSize:'20px', color:'black', fontWeight: 'bolder'}} className="text-center">{`Grade Levels: (${grades.map((grade)=> `${grade}`)})`}</h1>

        </div>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody className="border border-black/20">
              {visibleRows.map((row) => (
                <TableRow key={row.id} className="divide-x divide-black/20">
                  <TableCell
                  onClick={()=>{
                    push(`${showUrl("student", row?.id || "me")}`);
                  }

                  }
                    style={{
                      fontWeight: "bold",
                      fontSize: '18px'
                    }}
                  >
                    {row.studentName}
                  </TableCell>

                  <GradeCell wcpm={row.pretest} grade={row.grade} />
                  <GradeCell wcpm={row.dyslexia} grade={row.grade} dx = {true} />
                  <GradeCell wcpm={row.CP1} grade={row.grade} />
                  <GradeCell wcpm={row.CP2} grade={row.grade} />
                  <GradeCell wcpm={row.CP3} grade={row.grade} />
                  <GradeCell wcpm={row.l21} grade={row.grade} />
                  {/* <TableCell
                    style={{
                      border: "1px solid rgb(0 0 0 / 0.2)",
                      fontWeight: "bold",
                    }}
                    className="font-bold"
                  >
                    {row.firstName}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

function getWcpmColor(grade: number, wcpm?: string) {
  let wcpmVal: number;
  if (!wcpm || wcpm === '-') return "#f0f0f0";
  if(wcpm == 'K'){
    wcpmVal = 0;
    return "red"
  }else{
    wcpmVal = Number(wcpm);
  }
  if(grade==wcpmVal){
    return "#02c70c";
  }else if(wcpmVal>grade){
    return "#3449d1";
    
  }else if(wcpmVal == grade-1){
    return "#FEF250";
    
  }else if(grade >= wcpmVal-2){
    return "#eba42a";
    
  }else {
    return "#eba42a";
    
  }

}

function GradeCell({ wcpm, grade, dx=false }: { wcpm?: number | string; grade?: number | string; dx?: boolean }) {
  return (
    <TableCell
      style={(dx===true && wcpm != null)?
        {
        border: "1px solid rgb(0 0 0 / 0.2)",
        fontSize: '18px',
        background: '#808080',
        color: "black",
        textAlign: 'center',
      }:
        {
        border: "1px solid rgb(0 0 0 / 0.2)",
        fontSize: '18px',
        background: getWcpmColor(grade as number, wcpm as string),
        color: "black",
        textAlign: 'center',
      }}
    >
      {(dx===true&&wcpm!=null)?`${wcpm}/20`:wcpm}
    </TableCell>
  );
}
